import { useFlags } from '@nutrien/minesight-utility-module';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { checkRegistration, GetSecret } from '@/api/mTlsAuthenticationApi';
import { DEVICE_REGISTRATION_FLAG_KEY, MTLS_SELECTED_BORER_ID_KEY } from '@/utilities/constants';
import { GenericLoginError, MinesightError, MTlsLoginError } from '@/utilities/minesightErrors';

import { useMst } from '../../../mobx-models/Root';
import { useConstructor } from '../../../utilities';
import LoginArea from './LoginArea';
import NewPasswordArea from './NewPasswordArea';

enum LoginViews {
  LOGIN,
  NEW_PASSWORD,
}

interface Props {
  loginHandler: (history: any) => Promise<void>;
}

const Login: React.FC<Props> = ({ loginHandler }: Props) => {
  const history = useHistory();
  const [view, setViewType] = useState<LoginViews>(LoginViews.LOGIN);
  const [userData, setUserData] = useState<any>();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState<MinesightError | undefined>();
  const [useMTlsLogin, setUseMTlsLogin] = useState(false);
  const [registerIPad, setRegisterIPad] = useState(true);
  const { passwordlessLogin } = useFlags().flags;

  const { user } = useMst();

  const checkForSession = async () => {
    try {
      const userInfo = await Auth.currentUserInfo();
      if (!userInfo) return;
      await loginHandler(history);

      Sentry.setUser({ email: userInfo.attributes.email });
    } catch (error) {
      setLoginError(error);
      console.log('🚀 ~ file: Login.tsx ~ line 36 ~ checkForSession ~ error', error);
    }
  };

  useConstructor(() => {
    checkForSession();
  });

  const handleLogin = async (email: string, password: string) => {
    setIsLoggingIn(true);
    try {
      let awsUser;
      let lowerCaseEmail;
      if (passwordlessLogin && useMTlsLogin) {
        const response = await checkRegistration();
        if (response && response.isRegistered) {
          awsUser = await Auth.signIn(response.email);
          if (awsUser.challengeName === 'CUSTOM_CHALLENGE') {
            const challengeResponse = await GetSecret();
            awsUser = await Auth.sendCustomChallengeAnswer(
              awsUser,
              challengeResponse.secretLoginKey,
            );
            if (awsUser) {
              localStorage.setItem(MTLS_SELECTED_BORER_ID_KEY, response.borerId);
            }
          }
        } else {
          throw new Error('Device has certificate but is not registered');
        }
      } else {
        if (passwordlessLogin) {
          localStorage.setItem(DEVICE_REGISTRATION_FLAG_KEY, JSON.stringify(registerIPad));
        }

        lowerCaseEmail = email.toLowerCase();
        awsUser = await Auth.signIn(lowerCaseEmail, password);
      }

      if (awsUser) {
        if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserData(awsUser);
          setViewType(LoginViews.NEW_PASSWORD);
          return;
        }
        // Gets and assigns siteId to User Model
        await user.getProfile();

        Sentry.setUser({ email: lowerCaseEmail });
        await loginHandler(history);
      }
    } catch (error) {
      console.log('🚀 ~ file: Login.tsx:65 ~ handleLogin ~ error:', error);
      setLoginError(useMTlsLogin ? MTlsLoginError : error.code ? error : GenericLoginError);
      await Auth.signOut();
    }
    setIsLoggingIn(false);
  };

  const saveNewPassword = async (newPassword: string) => {
    try {
      await Auth.completeNewPassword(userData, newPassword);

      history.push('/home');
    } catch (error) {
      console.log('🚀 ~ file: Login.tsx ~ line 85 ~ saveNewPassword ~ error', error);
    }
  };

  const clearError = () => {
    setLoginError(undefined);
  };

  const getView = () => {
    switch (view) {
      case LoginViews.NEW_PASSWORD:
        return <NewPasswordArea saveNewPassword={saveNewPassword} />;
      case LoginViews.LOGIN:
      default:
        return (
          <LoginArea
            loginUser={handleLogin}
            isLoggingIn={isLoggingIn}
            error={loginError}
            clearError={clearError}
            useMTlsLogin={useMTlsLogin}
            setUseMTlsLogin={setUseMTlsLogin}
            registerIPad={registerIPad}
            setRegisterIPad={setRegisterIPad}
          />
        );
    }
  };

  return <>{getView()}</>;
};

export default Login;
