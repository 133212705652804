import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useState } from 'react';

import { EnrichedDocument } from '../../../../../rxdb/Documents/queryBuilder';
import { useNotification, useOnlineStatus } from '../../../../../utilities';
import useViewingCurrentShift from '../../../../../utilities/hooks/useViewingCurrentShift';
import { getAttachmentFromIndexDB } from '../../../../CacheUpdateHandler/documentUtilities';
import DocumentThumbnail from '../../../../DocumentThumbnail';
import ImagePreviewModal from '../../../../ImagePreviewModal';
import PDFPreviewModal from '../../../../PDFPreviewModal';
import { useSafeWorkPlanDocument } from './useSafeWorkPlanDocument';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },
    spacer: {
      marginLeft: 15,
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
    card: {
      padding: '16px',
    },
  }),
);

const SafeWorkPlanDocumentCard: FC = () => {
  const { safeWorkPlanDocument, safeWorkPlanDocumentAvailable } = useSafeWorkPlanDocument();
  const classes = useStyles();
  const viewingCurentShift = useViewingCurrentShift();

  const [pdfWindow, setPdfWindow] = useState<WindowProxy | undefined>();

  const isOnline = useOnlineStatus();
  const { errorNotification } = useNotification();

  const [imagePreviewModalOpen, setImagePreviewModalOpen] = useState(false);
  const [PDFPreviewModalOpen, setPDFPreviewModalOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<EnrichedDocument | undefined>();
  const [selectedDocumentData, setSelectedDocumentData] = useState<string | undefined>();

  const selectDocument = useCallback(
    async (document: EnrichedDocument, windowProxy?: WindowProxy) => {
      let url = null;
      if (document.isCached) {
        // If we have the doc cached, get it from the cache
        url = await getAttachmentFromIndexDB(document.fileName);
      }

      if (url) setSelectedDocumentData(url);
      else {
        errorNotification(
          'Error opening document. Please try again or check your network connection.',
        );
        return;
      }

      setImagePreviewModalOpen(true);
      setSelectedDocument(document);

      if (windowProxy) {
        setPdfWindow(windowProxy);
        setPDFPreviewModalOpen(true);
      } else {
        setImagePreviewModalOpen(true);
      }
    },
    [errorNotification, isOnline],
  );

  const unselectDocument = () => {
    setImagePreviewModalOpen(false);
    setPDFPreviewModalOpen(false);
    setSelectedDocumentData(undefined);
    setPdfWindow(undefined);
  };

  const prepareToSelectDocument = useCallback(
    (doc: EnrichedDocument) => {
      if (doc.fileName?.toLowerCase().includes('.pdf')) {
        const windowProxy = window.open();
        selectDocument(doc, windowProxy || undefined);
      } else {
        selectDocument(doc);
      }
    },
    [selectDocument],
  );

  // Don't show card if no safe work plan available
  if (!safeWorkPlanDocumentAvailable) return null;
  if (!viewingCurentShift) return null;

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography variant="h3">{i18n.t('Safe work plan')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-start" data-testid="safe-work-plan-documents">
              <>
                {safeWorkPlanDocument?.map(document => (
                  <DocumentThumbnail
                    document={document}
                    selectDocument={prepareToSelectDocument}
                    key={document.id}
                  />
                ))}
              </>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <ImagePreviewModal
        open={imagePreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
      />
      <PDFPreviewModal
        open={PDFPreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
        pdfWindow={pdfWindow}
      />
    </>
  );
};

export default observer(SafeWorkPlanDocumentCard);
