import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { useMemo } from 'react';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';

import { InspectionType } from '../../utilities/constants';
import { WorkFaceAreaInspectionType } from '../../utilities/enums';
import useBorerWfai from '../../utilities/hooks/useBorerWfai';

export interface HazardMenuOption {
  label: string;
  order: number;
  inspectionType?: InspectionType | WorkFaceAreaInspectionType;
  showWhenWfaiEnabled?: boolean;
  isWfai?: boolean;
  isCuttingPermit?: boolean;
  showOnRehab?: boolean;
}

const coryMenuOptions: HazardMenuOption[] = [
  {
    label: 'Work face area inspection (Production)',
    order: 1,
    inspectionType: WorkFaceAreaInspectionType.PRODUCTION,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Work face area inspection (other)',
    order: 2,
    inspectionType: WorkFaceAreaInspectionType.OTHER,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Area check',
    order: 1,
    inspectionType: InspectionType.CORY_AREA_CHECK,
    showWhenWfaiEnabled: false,
    isWfai: false,
  },
  {
    label: 'Refuge check',
    order: 3,
    inspectionType: InspectionType.CORY_REFUGE_CHECK,
    showWhenWfaiEnabled: true,
    isWfai: false,
  },
  {
    label: 'Cutting permit',
    order: 5,
    showWhenWfaiEnabled: true,
    isCuttingPermit: true,
  },
  {
    label: 'Rehab Cutting permit',
    order: 6,
    inspectionType: InspectionType.REHAB_CUTTING_PERMIT,
    showWhenWfaiEnabled: true,
    isCuttingPermit: true,
    showOnRehab: false,
  },
  {
    label: 'Lunch area check (start of shift)',
    order: 7,
    inspectionType: InspectionType.CORY_LUNCH_AREA_CHECK,
    showWhenWfaiEnabled: true,
    isWfai: false,
  },
  {
    label: 'Monthly panel inspection',
    order: 8,
    inspectionType: InspectionType.CORY_MONTHLY_PANEL_INSPECTION,
    showWhenWfaiEnabled: true,
    isWfai: false,
  },
];

const vanscoyMenuOptions: HazardMenuOption[] = [
  {
    label: 'Cutting permit',
    order: 0,
    showWhenWfaiEnabled: true,
    isCuttingPermit: true,
    showOnRehab: false,
  },
  {
    label: 'Area check',
    order: 1,
    inspectionType: InspectionType.AREA_CHECK,
    showWhenWfaiEnabled: false,
    isWfai: false,
    showOnRehab: false,
  },
  {
    label: 'Work face area inspection (Production)',
    order: 1,
    inspectionType: WorkFaceAreaInspectionType.PRODUCTION,
    showWhenWfaiEnabled: true,
    isWfai: true,
    showOnRehab: false,
  },
  {
    label: 'Work face area inspection (other)',
    order: 2,
    inspectionType: WorkFaceAreaInspectionType.OTHER,
    showWhenWfaiEnabled: true,
    isWfai: true,
    showOnRehab: false,
  },
  {
    label: 'Area pre-op/cutting check',
    order: 3,
    inspectionType: InspectionType.VANSCOY_AREA_PREOP_CUTTING_CHECK,
    showWhenWfaiEnabled: true,
    isWfai: false,
    showOnRehab: false,
  },
  {
    label: 'Panel inspection',
    order: 4,
    inspectionType: InspectionType.VANSCOY_PANEL_INSPECTION,
    showWhenWfaiEnabled: true,
    isWfai: false,
    showOnRehab: true,
  },
  {
    label: 'Safety checks before cutting',
    order: 0,
    inspectionType: InspectionType.VANSCOY_SAFETY_CHECK_BEFORE_CUTTING,
    showOnRehab: true,
  },
  {
    label: 'Safety checks during cutting',
    order: 1,
    inspectionType: InspectionType.VANSCOY_SAFETY_CHECK_DURING_CUTTING,
    showOnRehab: true,
  },
];

const laniganMenuOptions: HazardMenuOption[] = [
  {
    label: 'Area check',
    order: 1,
    inspectionType: InspectionType.AREA_CHECK,
    showWhenWfaiEnabled: false,
    isWfai: false,
  },

  {
    label: 'Work face area inspection (Production)',
    order: 3,
    inspectionType: WorkFaceAreaInspectionType.PRODUCTION,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Work face area inspection (other)',
    order: 4,
    inspectionType: WorkFaceAreaInspectionType.OTHER,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Cutting permit',
    order: 5,
    showWhenWfaiEnabled: true,
    isCuttingPermit: true,
  },
];

export const rocanvilleMenuOptions: HazardMenuOption[] = [
  {
    label: 'Mining Face Inspection',
    order: 1,
    inspectionType: WorkFaceAreaInspectionType.PRODUCTION,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Lunch table',
    order: 2,
    showWhenWfaiEnabled: true,
    inspectionType: InspectionType.AREA_CHECK_LUNCH_TABLE,
  },
  {
    label: 'Extensible drive',
    order: 3,
    showWhenWfaiEnabled: true,
    inspectionType: InspectionType.AREA_CHECK_EXTENSIBLE_DRIVE,
  },
  {
    label: 'Breakthrough room',
    order: 4,
    showWhenWfaiEnabled: true,
    inspectionType: InspectionType.AREA_CHECK_BREAKTHROUGH_ROOM,
  },
  {
    label: 'Cutting permit',
    order: 5,
    showWhenWfaiEnabled: true,
    isCuttingPermit: true,
    inspectionType: InspectionType.CUTTING_PERMIT,
  },
  {
    label: 'Bit rack check',
    order: 6,
    showWhenWfaiEnabled: true,
    inspectionType: InspectionType.BIT_RACK_CHECK,
  },
];

export const allanMenuOptions: HazardMenuOption[] = [
  {
    label: 'Work face area inspection (Production)',
    order: 1,
    inspectionType: WorkFaceAreaInspectionType.PRODUCTION,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Work face area inspection (other)',
    order: 2,
    inspectionType: WorkFaceAreaInspectionType.OTHER,
    showWhenWfaiEnabled: true,
    isWfai: true,
  },
  {
    label: 'Area check',
    order: 3,
    inspectionType: InspectionType.AREA_CHECK,
    showWhenWfaiEnabled: false,
  },
  {
    label: 'Refuge check',
    order: 4,
    showWhenWfaiEnabled: true,
    inspectionType: InspectionType.AREA_CHECK_REFUGE,
  },
  {
    label: 'Cutting permit',
    order: 5,
    showWhenWfaiEnabled: true,
    isCuttingPermit: true,
    inspectionType: InspectionType.CUTTING_PERMIT,
  },
];

export const useHazardMenuOptions = () => {
  const { isCory, isVanscoy, isLanigan, isRocanville, isAllan } = useSiteFeatures();
  const { useWfai } = useBorerWfai();
  const { isRehabBorer } = useCurrentBorer();

  const menuOptions = useMemo(() => {
    let menuOptionArray;
    if (isCory) {
      menuOptionArray = coryMenuOptions;
    } else if (isVanscoy) {
      menuOptionArray = vanscoyMenuOptions;
    } else if (isLanigan) {
      menuOptionArray = laniganMenuOptions;
    } else if (isRocanville) {
      menuOptionArray = rocanvilleMenuOptions;
    } else if (isAllan) {
      menuOptionArray = allanMenuOptions;
    }

    return menuOptionArray
      ?.slice()
      ?.sort((a, b) => a.order - b.order)
      .filter(item => {
        if (isRehabBorer && (isVanscoy || isCory) && item.showOnRehab !== undefined) {
          return item.showOnRehab;
        } else {
          return useWfai ? item.showWhenWfaiEnabled : !item.showWhenWfaiEnabled || !item.isWfai;
        }
      });
  }, [isCory, isVanscoy, isLanigan, useWfai, isRocanville, isAllan, isRehabBorer]);

  return menuOptions;
};

export default useHazardMenuOptions;
