import {
  AppBar as MUIAppbar,
  Badge,
  Grid,
  IconButton,
  Popover,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  darkTheme,
  Dialog,
  i18n,
  Icons,
  MaterialPalette,
  Typography,
} from '@nutrien/cxp-components';
import { useFlags } from '@nutrien/minesight-utility-module';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';
import { RxChangeEventInsert } from 'rxdb/dist/types/types';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import RegisterDeviceModal from '@/components/AppBar/RegisterDeviceModal/RegisterDeviceModal';
import { useMst } from '@/mobx-models/Root';
import { BorerShiftCollection } from '@/rxdb/BorerShift/queryBuilder';
import useBorerShift from '@/rxdb/BorerShift/useBorerShift';
import useBorerShiftCrew from '@/rxdb/BorerShiftCrew/useBorerShiftCrew';
import { RxdbCollectionName } from '@/rxdb/rxdbCollectionName';
import { Shift, ShiftDocument } from '@/rxdb/Shifts/queryBuilder';
import useShifts from '@/rxdb/Shifts/useShifts';
import useRxDB from '@/rxdb/useRxDB';
import { useNotification, useOnlineStatus } from '@/utilities';
import { SHOW_REFRESH_COMPLETE } from '@/utilities/constants';
import { ShiftType } from '@/utilities/enums';
import { useCrewAssignedForShift } from '@/utilities/hooks/useCrewAssignedForShift';
import { AWS_DATE_TIME_FORMAT } from '@/utilities/hooks/useDateFormatters';
import useDebug from '@/utilities/hooks/useDebug';
import useEventTracking, { TrackingEventType } from '@/utilities/hooks/useEventTracking';
import { useViewingCurrentShift } from '@/utilities/hooks/useViewingCurrentShift';
import { getBorerShortName } from '@/utilities/utilityFunctions';

import MissingCrewDialog from '../MissingCrewDialog';
import MissingDataDialog from '../MissingDataDialog';
import { useStyles } from './AppBar.styles';
import CustomAvatarIcon from './CustomAvatarIcon';
import CustomDatePicker from './CustomDatePicker';
import LogoutModal from './LogoutModal';
import ResetAppDataModal from './ResetAppDataModal/ResetAppDataModal';
import UserMenu from './UserMenu';
const getEnvName = () => {
  const { href } = window.location;

  if (href.includes('0.0.0.0') || href.includes('localhost')) {
    return 'Local';
  }
  if (href.includes('dev')) {
    return 'Dev';
  }
  if (href.includes('stage')) {
    return 'Stage';
  }
  if (href.includes('training')) {
    return 'Training';
  }
  if (href.includes('uat')) {
    return 'UAT';
  }
  if (href.includes('rc')) {
    return 'RC';
  }
};

const SNACKBAR_KEY = 'SHOWING_CURRENT_SHIFT';

const AppBar: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker, appVersion, equipment } = useMst();
  const showEnvWord = useMediaQuery('(min-width:1050px)');
  const { getCurrentBorerShift } = useBorerShift();
  const { shiftsInitialized, shiftCollection, selectedShiftInDB, selectedBorerShiftInDB } =
    useShifts();
  const { trackEvent } = useEventTracking();

  const DEBUG = useDebug();
  const { borerShiftCrewInitialized, borerShiftCrewCollection } = useBorerShiftCrew();
  const online = useOnlineStatus();
  const history = useHistory();
  const envName = getEnvName();

  const { db } = useRxDB();

  const [collectionStatesOpen, setCollectionStatesOpen] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);
  const [resetAppDataModalOpen, setResetAppDataModalOpen] = useState<boolean>(false);
  const [registerDeviceModalOpen, setRegisterDeviceModalOpen] = useState(false);
  const [lastOnlineOpen, setLastOnlineOpen] = useState<any>(null);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const borerShiftCollection = useRxCollection<BorerShiftCollection>(
    RxdbCollectionName.BORER_SHIFT,
  );
  const userViewingCurrentShift = useViewingCurrentShift();
  const [missingCrewDialogOpen, setMissingCrewDialogOpen] = useState(false);

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCurrentShiftDocFromDateType = async (
    date: Dayjs,
    type: ShiftType,
  ): Promise<ShiftDocument | undefined> => {
    const utcDate = `${date.utc().startOf('day').format(AWS_DATE_TIME_FORMAT)}Z`;

    if (!shiftCollection) return undefined;

    try {
      const result: ShiftDocument[] = await shiftCollection
        .find({
          selector: {
            name: { $eq: type },
            day: { $eq: utcDate },
          },
        })
        .exec();

      if (!result || result?.length < 1) return undefined;
      return result[0];
    } catch (error) {
      console.log('🚀 ~ file: AppBar.tsx ~ line 107 ~ onShiftAndDateSelected ~ error', error);
      return undefined;
    }
  };

  const onShiftAndDateSelected = async (date: Dayjs, type: ShiftType) => {
    try {
      shiftPicker.setShift(date, type);

      const shiftDoc = await getCurrentShiftDocFromDateType(date, type);
      if (shiftDoc?.id) {
        shiftPicker.setCurrentShift(shiftDoc);
        const borerShift = await getCurrentBorerShift(shiftDoc.id);
        shiftPicker.setCurrentBorerShift(borerShift || null);
      } else {
        shiftPicker.resetShiftAndBorerShiftToNull();
      }
    } catch (error) {
      errorNotification('Unable to move to selected shift');
    }
  };

  const handleNewShiftInsertion = async (shift: RxChangeEventInsert<Shift>) => {
    if (DEBUG) console.info('New shift inserted...', shift);

    // On New Shift, check if we dont have a selected shift
    if (shiftPicker.currentShiftId === null) {
      if (DEBUG) console.info('No current shiftId...');
      // Check if we have a selected shift date and type
      if (shiftPicker.Date && shiftPicker.Type) {
        // If we do then check if the dates match the shift just inserted
        const newShiftDate = shift.documentData.day.slice(0, 10);
        const dateMatch = newShiftDate === shiftPicker.Date;
        const typeMatch = shift.documentData.name === shiftPicker.Type;

        if (DEBUG) console.info('Dates match?...', dateMatch);
        if (DEBUG) console.info('Types match?...', typeMatch);

        if (dateMatch && typeMatch) {
          if (DEBUG)
            console.info('New shift inserted matches current selected shiftDate and type...');
          onShiftAndDateSelected(shiftPicker.selectedShiftDate, shiftPicker.Type);
        }
      }
    }
  };

  // Shifts Handling
  useEffect(() => {
    let shiftSubscription: Subscription | undefined;
    if (shiftsInitialized) {
      shiftSubscription = shiftCollection?.insert$.subscribe(async shift =>
        handleNewShiftInsertion(shift),
      );
    }

    return () => {
      if (shiftSubscription) shiftSubscription.unsubscribe();
    };
  }, [shiftsInitialized]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setUserMenuAnchorEl(null);
  }, []);

  const updateShiftId = async (date: Dayjs, type: ShiftType) => {
    const shiftDoc = await getCurrentShiftDocFromDateType(date, type);
    if (shiftDoc?.id && borerShiftCollection) {
      shiftPicker.setCurrentShift(shiftDoc);
      const borerShift = await borerShiftCollection
        .findOne({
          selector: {
            shiftId: shiftDoc.id,
          },
        })
        .exec();

      if (borerShift && borerShift !== null) {
        shiftPicker.setCurrentBorerShift(borerShift);
      } else {
        console.error(`could not set borer shift id for shift ${shiftDoc.id}`);
      }
    } else {
      shiftPicker.resetShiftAndBorerShiftToNull();
    }
  };

  useEffect(() => {
    // Only call if we don't have the list of borers from previous login step (ie page refresh)
    if (!equipment.getBorerList(true, true).length) {
      equipment.fetchBorerList();
    }

    const interval = setInterval(() => {
      if (!shiftPicker.currentBorerShiftId) {
        const { Date, Type } = shiftPicker;
        const date = dayjs(Date);

        updateShiftId(date, Type);
      }
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [shiftPicker.currentBorerShiftId]);

  useEffect(() => {
    const { Date, Type } = shiftPicker;
    const date = dayjs(Date);

    updateShiftId(date, Type);
  }, [shiftPicker, updateShiftId, shiftPicker.Date, shiftPicker.Type, borerShiftCollection]);

  // subscribe to borerShiftCrew
  useEffect(() => {
    if (borerShiftCrewInitialized === true && borerShiftCrewCollection) {
      borerShiftCrewCollection.$.subscribe(() => {
        const { Date, Type } = shiftPicker;
        const date = dayjs(Date);
        updateShiftId(date, Type);
      });
    }
  }, [borerShiftCrewInitialized]);

  // Viewing Previous Shift Indicator
  const { errorNotification, closeSnackbar, successNotification } = useNotification();

  const crewAssigned = useCrewAssignedForShift();
  useEffect(() => {
    if (!crewAssigned) {
      setMissingCrewDialogOpen(true);
    } else {
      setMissingCrewDialogOpen(false);
    }
  }, [crewAssigned]);

  // Show notification when not viewing current shift.
  useEffect(() => {
    if (!userViewingCurrentShift) {
      errorNotification(`Viewing a past shift`, {
        key: SNACKBAR_KEY,
        persist: true,
        action: () => (
          <Button
            noMinHeight
            color="primary"
            variant="text"
            onClick={shiftPicker.resetToCurrentShift}
            id="shift-picker-reset-to-current-shift"
          >
            Return to current shift
          </Button>
        ),
      });
    } else {
      closeSnackbar(SNACKBAR_KEY);
    }
  }, [userViewingCurrentShift]);

  const onLogoutSelected = useCallback(() => {
    setLogoutOpen(true);
  }, []);

  const onRebuildClicked = useCallback(() => {
    setResetAppDataModalOpen(true);
  }, []);

  const onRegisterDeviceClicked = useCallback(() => {
    setRegisterDeviceModalOpen(true);
  }, []);

  const onLogout = async () => {
    setLogoutLoading(true);
    await trackEvent(TrackingEventType.LOGOUT_CLICKED, {
      location: 'AppBar',
    });
    setLogoutLoading(false);
    history.push('/logout');
  };

  const onLastOnlineClose = () => {
    setLastOnlineOpen(false);
  };

  const handleLastOnlineClick = () => {
    const lastOnline = localStorage.getItem('lastOnline');
    const lastOnlineTwoMins = dayjs.unix(Number(lastOnline) + 2 * 60).unix();
    if (lastOnline && dayjs().unix() > lastOnlineTwoMins) setLastOnlineOpen(true);
  };

  const { borerOutageWarningBanner, msBorerDataSync, borerOutageBanner, displayrxdbsyncindicator } =
    useFlags().flags;

  const onCloseMissingCrewDialog = () => {
    setMissingCrewDialogOpen(false);
  };

  useEffect(() => {
    const showRefreshComplete = sessionStorage.getItem(SHOW_REFRESH_COMPLETE);
    if (showRefreshComplete && JSON.parse(showRefreshComplete) === true) {
      sessionStorage.removeItem(SHOW_REFRESH_COMPLETE);
      successNotification(i18n.t('Refresh Complete'));
    }
  }, []);

  const totalCollections = Object.keys(db?.db?.collections || {}).length;
  const [stoppedCollections, setStoppedCollections] = useState<RxGraphQLReplicationState<any>[]>(
    [],
  );
  const [runningCollections, setRunningCollections] = useState<RxGraphQLReplicationState<any>[]>(
    [],
  );

  const checkForSyncRunning = useCallback(async () => {
    setStoppedCollections(db?.syncStates.filter(syncState => syncState.isStopped() === true) || []);
    setRunningCollections(
      db?.syncStates.filter(syncState => syncState.isStopped() === false) || [],
    );
  }, [db?.syncStates]);

  useEffect(() => {
    checkForSyncRunning();

    const interval = setInterval(() => {
      checkForSyncRunning();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [checkForSyncRunning]);

  const onCollectionStatesClose = () => {
    setCollectionStatesOpen(false);
  };

  const handleCollectionStatesClick = () => {
    setCollectionStatesOpen(true);
  };

  const onRestartSync = async () => {
    try {
      await db?.startReplication();
      successNotification(i18n.t('Sync restarted'));
      setCollectionStatesOpen(false);
    } catch (e) {
      console.error(e);
      errorNotification(i18n.t('Sync restart failed'));
    }
  };

  return (
    <MUIAppbar position="static" className={classes.appBar}>
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item container xs={3}>
            <Typography color="primary" variant="h5">
              {equipment.selectedBorerName || getBorerShortName()}
            </Typography>
            {envName && (
              <Typography variant="h5" color="textSecondary" className={classes.envTitle}>
                {envName} {showEnvWord && 'Environment'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} container justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <IconButton
                onClick={() => {
                  shiftPicker.moveShift(false);
                }}
                id="shift-picker-previous-shift"
              >
                <Icons.ArrowLeftCircleFeather color="primary" />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                noMinHeight
                variant="text"
                endAdornment={
                  anchorEl ? (
                    <Icons.ChevronUpFeather
                      stroke={darkTheme.palette.text.secondary}
                      className={classes.chevronDown}
                    />
                  ) : (
                    <Icons.ChevronDownFeather
                      stroke={darkTheme.palette.text.secondary}
                      className={classes.chevronDown}
                    />
                  )
                }
                onClick={handleClick}
                classes={{ label: classes.datePickerButton }}
                data-testid="shift-picker-date-picker"
              >
                {shiftPicker.shiftLabelText}
              </Button>
              <CustomDatePicker
                id="simple"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                currentDateText={shiftPicker.shiftLabelText}
                selectedDate={shiftPicker.selectedShiftDate}
                selectedShiftType={shiftPicker.Type}
                onShiftAndDateSelected={onShiftAndDateSelected}
                currentDateNightShiftStart={shiftPicker.currentDateNightShiftStart}
                currentShift={shiftPicker.currentShift()}
                isNightShiftOfPreviousDay={dayjs().isBefore(shiftPicker.currentDayShiftStartTime)}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  shiftPicker.moveShift(true);
                }}
                disabled={userViewingCurrentShift}
                id="shift-picker-next-shift"
              >
                <Icons.ArrowRightCircleFeather
                  color={userViewingCurrentShift ? 'disabled' : 'primary'}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container xs={3} justify="flex-end" spacing={4}>
            {displayrxdbsyncindicator && (
              <Grid item>
                {online && stoppedCollections.length === 0 && runningCollections.length !== 0 ? (
                  <Icons.CloudFeather color="primary" />
                ) : (
                  <Icons.CloudOffFeather
                    color="inherit"
                    style={{
                      stroke:
                        stoppedCollections.length === totalCollections
                          ? MaterialPalette.error.main
                          : darkTheme.palette.warning.main,
                    }}
                    onClick={handleCollectionStatesClick}
                  />
                )}
              </Grid>
            )}
            <Grid item>
              {online && msBorerDataSync?.allowSync ? (
                <Icons.WifiFeather color="primary" />
              ) : (
                <Icons.WifiOffFeather
                  color="error"
                  onClick={handleLastOnlineClick}
                  id="shift-picker-wifi-off"
                />
              )}
            </Grid>
            <Grid item onClick={openMenu} className={classes.userMenuIcon} id="open-user-menu">
              {appVersion.hasNewUpdate ? (
                <Badge badgeContent={1} color="error">
                  <CustomAvatarIcon />
                </Badge>
              ) : (
                <CustomAvatarIcon />
              )}
            </Grid>
          </Grid>
        </Grid>
        <UserMenu
          id="user-menu"
          open={Boolean(userMenuAnchorEl)}
          anchorEl={userMenuAnchorEl}
          onClose={handleMenuClose}
          onLogout={onLogoutSelected}
          onRebuildClicked={onRebuildClicked}
          onRegisterDeviceClicked={onRegisterDeviceClicked}
        />
        <LogoutModal
          open={logoutOpen}
          onCancel={() => setLogoutOpen(false)}
          onLogout={onLogout}
          disableEnforceFocus={false}
          logoutLoading={logoutLoading}
        />
        <ResetAppDataModal
          open={resetAppDataModalOpen}
          onCancel={() => setResetAppDataModalOpen(false)}
        />
        <RegisterDeviceModal
          open={registerDeviceModalOpen}
          onClose={() => setRegisterDeviceModalOpen(false)}
        />
        <Popover
          id="last-online"
          open={Boolean(lastOnlineOpen)}
          anchorEl={lastOnlineOpen}
          onClose={onLastOnlineClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.paper }}
        >
          <Grid item xs={11} className={classes.infoTextContainer}>
            <Grid item xs={1} className={classes.infoIcon}>
              <Icons.InfoFeather color="error" />
            </Grid>
            <Typography variant="h6" align="left" className={classes.offlineText}>
              {'Last Online: '}
              {dayjs.unix(Number(localStorage.getItem('lastOnline'))).format('hh:mm a')}
            </Typography>
          </Grid>
        </Popover>
        <Popover
          id="collections-sync-popover"
          open={Boolean(collectionStatesOpen)}
          anchorEl={collectionStatesOpen}
          onClose={onCollectionStatesClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.paper }}
        >
          <Grid item xs={12} className={classes.infoTextContainer}>
            <Typography variant="h6" align="left" className={classes.offlineText}>
              {`${stoppedCollections.length} of ${db?.syncStates.length} collections are not syncing`}
            </Typography>
          </Grid>
          {stoppedCollections.length > 0 && online ? (
            <Grid item xs={12} className={classes.infoTextContainer}>
              <Button
                noMinHeight
                variant="outlined"
                endAdornment={<Icons.RefreshCwFeather color="primary" />}
                color="primary"
                onClick={onRestartSync}
                css={undefined}
                id="restart-sync"
                data-testid="restart-sync"
              >
                Restart sync
              </Button>
            </Grid>
          ) : null}
          {stoppedCollections.map(state => (
            <Grid
              item
              xs={12}
              className={classes.infoTextContainer}
              key={state.collection.name + '-stopped'}
            >
              <Typography variant="h6" align="left" className={classes.offlineText}>
                {`${state.collection.name} is not syncing`}
              </Typography>
            </Grid>
          ))}
        </Popover>
      </Toolbar>
      {borerOutageBanner?.display && (
        <>
          <Dialog
            titleText="Unavailable"
            data-cy="UnavailableModal"
            open={borerOutageBanner?.display}
            onClose={() => {}}
            backButton={false}
            maxWidth="xs"
            maxHeight="150px"
            className={classes.dialog}
            BackdropProps={{
              onClick: event => {
                event.preventDefault();
              },
              onTouchStart: event => {
                event.preventDefault();
              },
              style: { touchAction: 'none' },
            }}
          >
            <Grid item xs={12}>
              {borerOutageBanner?.message || ''}
            </Grid>
          </Dialog>
        </>
      )}
      {borerOutageWarningBanner?.display && (
        <Grid container className={classes.alertContainer}>
          <Grid item xs={12}>
            <Alert severity="info" icon={false} className={classes.info}>
              <Grid container>
                <Grid item xs={12}>
                  <>{borerOutageWarningBanner?.message || ''}</>
                </Grid>
              </Grid>
            </Alert>
          </Grid>
        </Grid>
      )}
      {missingCrewDialogOpen && <MissingCrewDialog onClose={onCloseMissingCrewDialog} />}
      {(!selectedShiftInDB || !selectedBorerShiftInDB) && <MissingDataDialog />}
    </MUIAppbar>
  );
};

export default observer(AppBar);
