import { createStyles, Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip, CustomPalette, i18n, MaterialPalette } from '@nutrien/cxp-components';
import { useFlags } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import { checkRegistration } from '@/api/mTlsAuthenticationApi';
import useRxDB from '@/rxdb/useRxDB';
import { getBorerId } from '@/utilities/utilityFunctions';

import { useMst } from '../../mobx-models/Root';
import { useOnlineStatus } from '../../utilities';
import { SHOW_REFRESH_COMPLETE, USER_EMAIL_ID } from '../../utilities/constants';

const useStyles = makeStyles(() =>
  createStyles({
    container: { maxWidth: '340px' },
    paper: {
      zIndex: '2100 !important', // above outage banner layer
      maxWidth: '300px',
      minWidth: '250px',
      padding: '8px',
      backgroundColor: CustomPalette.elevation.dp24Solid,
    },
    chip: {
      marginLeft: '8px',
      backgroundColor: `${MaterialPalette.error.main} !important`,
    },
    button: {
      width: 'fit-content !important',
    },
  }),
);

interface Props {
  id: string;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onLogout: () => void;
  onRebuildClicked: () => void;
  onRegisterDeviceClicked: () => void;
}

const UserMenu: React.FC<Props> = ({
  id,
  open,
  anchorEl,
  onClose,
  onLogout,
  onRebuildClicked,
  onRegisterDeviceClicked,
}: Props) => {
  const classes = useStyles();
  const { appVersion } = useMst();
  const {
    borerBlockUpdate,
    borerRefreshButtonEnabled,
    borerRebuildDatbaseButtonEnabled,
    passwordlessLogin,
  } = useFlags().flags;

  const [registerDeviceButtonEnabled, setRegisterDeviceButtonEnabled] = useState(false);

  const { db } = useRxDB();

  const isOnline = useOnlineStatus();

  const onLogoutClicked = () => {
    onClose();
    onLogout();
  };

  const onRefreshClicked = useCallback(async () => {
    sessionStorage.setItem(SHOW_REFRESH_COMPLETE, 'true');
    await db?.stopReplication();
    await db?.waitForIdle();
    window.location.reload();
  }, [db]);

  const setup = async () => {
    setRegisterDeviceButtonEnabled(false);
    if (passwordlessLogin && isOnline) {
      try {
        const userEmail = localStorage.getItem(USER_EMAIL_ID);
        const borerId = getBorerId();
        const response = await checkRegistration();
        if (
          (response && !response.success) ||
          (response.isRegistered && (response.email !== userEmail || response.borerId !== borerId))
        ) {
          setRegisterDeviceButtonEnabled(true);
        }
      } catch (error) {
        console.log('🚀 ~ file: UserMenu.tsx:87 ~ checkRegistration ~ error:', error);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setup().then(() => {});
    }
  }, [open]);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <Button variant="text" color="white" noMinHeight disabled className={classes.button}>
                {localStorage.getItem(USER_EMAIL_ID)}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Button
                variant="text"
                color="white"
                noMinHeight
                onClick={() => {
                  appVersion.openUpdateModal();
                  onClose();
                }}
                disabled={!appVersion.hasNewUpdate || !borerBlockUpdate}
                className={classes.button}
                id="update-button"
              >
                {`${i18n.t('Version')} ${appVersion.currentAppVersion}`}
                {appVersion.hasNewUpdate && (
                  <Chip
                    label={
                      borerBlockUpdate ? i18n.t('Update Available') : i18n.t('Update disabled')
                    }
                    size="small"
                    disabled={!borerBlockUpdate}
                    classes={{ root: classes.chip }}
                    textColor={MaterialPalette.common.white}
                  />
                )}
              </Button>
            </div>
          </Grid>
          {borerRefreshButtonEnabled && (
            <Grid item container xs={12}>
              <Button
                variant="text"
                color="white"
                noMinHeight
                onClick={onRefreshClicked}
                className={classes.button}
                id="refresh-button"
              >
                {i18n.t('Refresh page')}
              </Button>
            </Grid>
          )}
          {borerRebuildDatbaseButtonEnabled && (
            <Grid item container xs={12}>
              <Button
                variant="text"
                color="white"
                noMinHeight
                disabled={!isOnline}
                onClick={() => {
                  onClose();
                  onRebuildClicked();
                }}
                className={classes.button}
                id="rebuild-button"
              >
                {i18n.t('Reset app data')}
              </Button>
            </Grid>
          )}
          {registerDeviceButtonEnabled && (
            <Grid item container xs={12}>
              <Button
                variant="text"
                color="white"
                noMinHeight
                disabled={!isOnline}
                onClick={() => {
                  onClose();
                  onRegisterDeviceClicked();
                }}
                className={classes.button}
                id="register-device-button"
              >
                {i18n.t('Register device')}
              </Button>
            </Grid>
          )}
          <Grid item container xs={12}>
            <Button
              variant="text"
              color="white"
              noMinHeight
              onClick={onLogoutClicked}
              className={classes.button}
              disabled={!isOnline}
              id="logout-button"
            >
              {i18n.t('Log Out')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default observer(UserMenu);
