import { createStyles, Grid } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Chip,
  CustomPalette,
  i18n,
  Icons,
  MaterialPalette,
  Typography,
} from '@nutrien/cxp-components';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { LocationDocument } from '../../models/models';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import { GroundHazard } from '../../rxdb/GroundHazard/queryBuilder';
import useGroundHazards from '../../rxdb/GroundHazard/useGroundHazards';
import { GroundHazardAttachment } from '../../rxdb/GroundHazardAttachment/queryBuilder';
import useGroundHazardAttachments from '../../rxdb/GroundHazardAttachment/useGroundHazardAttachments';
import { GroundHazardConditionType } from '../../rxdb/HazardConditionType/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import { GROUND_HAZARD_WRITE_PERMISSION, InspectionType } from '../../utilities/constants';
import { WorkFaceAreaInspectionType } from '../../utilities/enums';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';
import { sortDescendingByCreatedOn } from '../../utilities/sortHelper';
import AddCommentSidePanel from '../AddCommentSidePanel';
import AddHazardsSidePanel from '../AddHazardsSidePanel';
import CuttingPermitModal from '../CuttingPermitModal';
import HazardMenu from '../HazardMenu';
import HazardPanelContent from '../HazardPanelContent';
import LocationDetailsComponent from '../LocationDetailsComponent';
import AddPreOpCheck from '../pages/Equipment/AddPreOpCheck';
import PanelDrawingEditModal from '../PanelDrawingEditModal';
import { ImageMetadata } from '../Thumbnail/Thumbnail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    titleLine: {
      marginBottom: '2px',
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    secondaryText: {
      color: theme.palette.text.disabled,
    },
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    icon: {
      color: theme.palette.primary.main,
    },
    smallText: {
      fontSize: '14px',
    },
    fullWidth: {
      width: '100%',
    },
  }),
);

export interface Props {
  showAddButton?: boolean;
  conditionsAlwaysExpanded?: boolean;
  hideAddPhotoAction?: boolean;
  hideAddComment?: boolean;
  alternateHeaderText?: string;
  newGroundHazardIds?: string[];
  overrideAddComment?: (groundHazardId: string, conditionType: string) => void;
  overrideHazardsToShowArray?: string[];
  hideCommentsBeforeDate?: Dayjs;
}

const ActiveConditionsCard: React.FC<Props> = ({
  showAddButton = true,
  conditionsAlwaysExpanded = true,
  hideAddPhotoAction = false,
  hideAddComment = false,
  alternateHeaderText,
  newGroundHazardIds,
  overrideAddComment,
  overrideHazardsToShowArray,
  hideCommentsBeforeDate,
}: Props) => {
  const classes = useStyles();
  const { trackEvent } = useEventTracking();
  const [expandedConditionId, setExpandedConditionId] = useState('');
  const [expandedCondition, setExpandedCondition] = useState<GroundHazard>();
  const [existingAttachmentFileInfo, setExistingAttachmentFileInfo] = useState<ImageMetadata[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [addHazardsOpen, setAddHazardsOpen] = useState(false);
  const [addCommentOpen, setAddCommentOpen] = useState(false);
  const [forceRehabCuttingPermit, setForceRehabCuttingPermit] = useState(false);
  const hazardsMenuOpen = Boolean(anchorEl);
  const [panelDrawingModalOpen, setPanelDrawingModalOpen] = useState(false);
  // Checklists
  const [addCuttingPermitOpen, setAddCuttingPermitOpen] = useState(false);
  const [preOpOpen, setPreOpOpen] = useState(false);
  const [newInspectionType, setNewInspectionType] = useState<InspectionType | undefined>();
  const { user, shiftPicker } = useMst();
  const history = useHistory();
  const { miningMethod } = useCurrentBorer();

  const { activeGroundHazards } = useGroundHazards({
    startTimeUnix: shiftPicker.selectedShiftStartUTC.unix(),
    endTimeUnix: shiftPicker.selectedShiftEndUTC.unix(),
    overrideHazardsToShowArray,
  });

  const { groundHazardAttachmentsInitialized, subscribeToGroundHazardAttachments } =
    useGroundHazardAttachments();

  const conditionTypesQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: conditionTypes }: { result: GroundHazardConditionType[] } = useRxData(
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
    conditionTypesQueryConstructor,
  );

  const locationsQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: locations }: { result: LocationDocument[] } = useRxData(
    RxdbCollectionName.LOCATIONS,
    locationsQueryConstructor,
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getConditionType = (conditionTypeId: string) => {
    return conditionTypes.find(c => c.id === conditionTypeId)?.displayName;
  };

  const getLocation = (locationId: string) => {
    return locations.find(l => l.id === locationId);
  };

  const onAddAreaCheck = (areaCheckType = InspectionType.AREA_CHECK) => {
    if (
      areaCheckType === InspectionType.VANSCOY_SAFETY_CHECK_BEFORE_CUTTING ||
      areaCheckType === InspectionType.VANSCOY_SAFETY_CHECK_DURING_CUTTING
    ) {
      setNewInspectionType(areaCheckType);
      setPreOpOpen(true);
      handleClose();
    } else {
      history.push(`/add-areacheck?type=${areaCheckType}`);
    }
  };

  const onAddWorkAreaInspection = (workFaceAreaInspectionType: WorkFaceAreaInspectionType) => {
    if (workFaceAreaInspectionType === WorkFaceAreaInspectionType.PRODUCTION) {
      history.push('/add-wfai?type=production');
    } else if (workFaceAreaInspectionType === WorkFaceAreaInspectionType.OTHER) {
      history.push('/add-wfai?type=other');
    }
  };
  const onAddCuttingPermit = (cuttingPermitType: InspectionType) => {
    setForceRehabCuttingPermit(cuttingPermitType === InspectionType.REHAB_CUTTING_PERMIT);
    handleClose();
    setAddCuttingPermitOpen(true);
  };

  const onCloseCuttingPermitModal = () => {
    setAddCuttingPermitOpen(false);
    setAnchorEl(null);
  };

  const activeConditionCards = useMemo(() => {
    const result = activeGroundHazards?.sort(sortDescendingByCreatedOn).map(condition => {
      return (
        <Fragment key={condition.id}>
          <Card id={condition.id} style={{ padding: '24px 24px 16px', margin: '8px 0px' }}>
            <Grid container style={{ marginBottom: '16px', paddingRight: '16px' }}>
              <Grid item container xs={8}>
                <Grid item container xs={12} spacing={1} className={classes.titleLine}>
                  <Typography variant="h5">{getConditionType(condition.conditionType)}</Typography>
                  <span className={classes.dot}>&#183;</span>
                  <Typography className={classes.secondaryText} variant="h5">
                    {condition.inspectionId ? i18n.t('Area Check') : i18n.t('Hazard')}
                  </Typography>
                  {!!newGroundHazardIds && newGroundHazardIds.includes(condition.id) && (
                    <>
                      <span className={classes.dot}>&#183;</span>
                      <Chip
                        color="secondaryDark"
                        label={i18n.t('New')}
                        textColor={MaterialPalette.common.white}
                        className={classes.dot}
                      />
                    </>
                  )}
                </Grid>
                {locations && locations.length > 0 && (
                  <LocationDetailsComponent
                    groundHazard={condition}
                    location={getLocation(condition.startLocation)}
                    hazardLandmark={condition.startHazardLandmark}
                  />
                )}
              </Grid>
              {(condition.id === expandedConditionId || conditionsAlwaysExpanded) &&
                !hideAddComment && (
                  <Grid item container xs={4} justify="flex-end">
                    {user.hasPermissionTo(GROUND_HAZARD_WRITE_PERMISSION) && (
                      <Button
                        id={`add-hazard-comment-button-${condition.id}`}
                        noMinHeight
                        color="primary"
                        startAdornment={<Icons.EditFeather color="primary" />}
                        onClick={() => {
                          if (overrideAddComment) {
                            overrideAddComment(
                              condition.id,
                              getConditionType(condition.conditionType) as string,
                            );
                          } else {
                            setExpandedCondition(condition);
                            setExpandedConditionId(condition.id);
                            setAddCommentOpen(true);
                          }
                        }}
                        style={{ maxWidth: '185px' }}
                      >
                        {i18n.t('Update hazard')}
                      </Button>
                    )}
                  </Grid>
                )}
            </Grid>
            <HazardPanelContent
              groundHazard={condition}
              conditionType={getConditionType(condition.conditionType)}
              hideAddPhotoAction={hideAddPhotoAction}
              hideCommentsBeforeDate={hideCommentsBeforeDate}
            />
          </Card>
        </Fragment>
      );
    });
    return result;
  }, [activeGroundHazards, locations]);

  const onCloseHazardsSidePanel = useCallback(() => {
    setAddHazardsOpen(false);
  }, []);

  const onOpenHazardsSidePanel = useCallback(() => {
    setAddHazardsOpen(true);
  }, []);

  const onNewHazardCreated = useCallback(() => {
    if (shiftPicker.isCurrentShiftSelected()) {
      setPanelDrawingModalOpen(true);
    }
  }, []);

  const onCloseAddCommentSidePanel = useCallback(() => {
    setAddCommentOpen(false);
  }, []);

  const onOpenAddCommentSidePanel = useCallback(() => {
    setAddCommentOpen(true);
  }, []);

  const onClosePreOp = () => {
    setNewInspectionType(undefined);
    setPreOpOpen(false);
    handleClose();
  };

  const getHazardImagesNames = async (attachments: GroundHazardAttachment[]) => {
    setExistingAttachmentFileInfo(attachments.map(a => ({ fileName: a.fileName })));
  };

  useEffect(() => {
    if (groundHazardAttachmentsInitialized && expandedConditionId) {
      const queryObj = {
        selector: {
          fileName: { $regex: `.*${expandedConditionId}*.` },
        },
      };
      subscribeToGroundHazardAttachments(getHazardImagesNames, queryObj);
    }
  }, [groundHazardAttachmentsInitialized, expandedConditionId]);

  return (
    <>
      <Card
        elevation={1}
        className={classes.card}
        divClass={classes.fullWidth}
        data-testid="active-conditions-card"
      >
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={10}>
              <Typography variant="h3">
                {`${alternateHeaderText || i18n.t('Active hazards')} (${
                  activeGroundHazards?.length || '0'
                })`}
              </Typography>
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              {showAddButton && user.hasPermissionTo(GROUND_HAZARD_WRITE_PERMISSION) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    noMinHeight
                    startAdornment={<Icons.PlusFeather />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    data-testid="add-hazard-menu"
                    id="add-hazard-menu"
                  >
                    {i18n.t('Add...')}
                  </Button>
                  <HazardMenu
                    open={hazardsMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    openAddHazard={() => {
                      handleClose();
                      setAddHazardsOpen(true);
                      trackEvent(TrackingEventType.HAZARD_ADD_OPEN, {
                        location: 'SINGLE_ENTRY',
                      });
                    }}
                    onAddAreaCheck={onAddAreaCheck}
                    onAddWorkAreaInspection={onAddWorkAreaInspection}
                    onAddCuttingPermit={onAddCuttingPermit}
                    isCurrentShiftSelected={shiftPicker.isCurrentShiftSelected()}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {activeConditionCards}
          </Grid>
        </Grid>
      </Card>
      <AddHazardsSidePanel
        open={addHazardsOpen}
        onClose={onCloseHazardsSidePanel}
        onOpen={onOpenHazardsSidePanel}
        onNewHazardCreated={onNewHazardCreated}
      />
      <AddCommentSidePanel
        open={addCommentOpen}
        onClose={onCloseAddCommentSidePanel}
        onOpen={onOpenAddCommentSidePanel}
        conditionId={expandedConditionId}
        groundHazard={expandedCondition}
        attachmentPrefix={`Borer_${getConditionType(expandedCondition?.conditionType as string)}_`}
        existingAttachmentFileInfo={existingAttachmentFileInfo}
      />
      <CuttingPermitModal
        open={addCuttingPermitOpen}
        onClose={onCloseCuttingPermitModal}
        onOpen={onAddCuttingPermit}
        forceRehabCuttingPermit={forceRehabCuttingPermit}
      />
      {panelDrawingModalOpen && miningMethod && (
        <PanelDrawingEditModal
          open={panelDrawingModalOpen}
          onClose={() => {
            setPanelDrawingModalOpen(false);
          }}
          miningMethod={miningMethod}
          saveEnabledByDefault={true}
        />
      )}
      <AddPreOpCheck
        open={preOpOpen}
        onClose={onClosePreOp}
        onOpen={onAddAreaCheck}
        inspectionType={newInspectionType}
      />
    </>
  );
};

export default observer(ActiveConditionsCard);
